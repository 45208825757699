import React from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Banner from "../components/banner";
import Section from "../components/section";
import Image from "../components/image";
import AdvTitle from '../components/advTitle';
import $ from 'jquery/dist/jquery';

import '../styles/product.less';


class Products extends React.Component
{
    constructor(props)
    {
        super(props);

        let state = {
            "businessUp": "active",
            "crowdfunding": "",
            "toolkit": "",
            "update": false
        };

        if (this.props.location.state)
        {
            state.businessUp = "";
            switch(props.location.state.activeTab)
            {
                case 'crowdfunding':
                    state.crowdfunding = "active";
                    break;
                case 'toolkit':
                    state.toolkit = "active";
                    break;
                default:
                    state.businessUp = "active";
            }
        }

        this.state = state;

        this.changeProduct = this.changeProduct.bind(this);
    }

    static getDerivedStateFromProps(props, state)
    {
        let newState = state;

        if (props.location.state && props.location.state.activeTab && !state.update)
        {
            newState.businessUp = '';
            newState.crowdfunding = '';
            newState.toolkit = '';
            switch(props.location.state.activeTab)
            {
                case 'crowdfunding':
                    newState.crowdfunding = "active";
                    newState.scroll = true;
                    break;
                case 'toolkit':
                    newState.toolkit = "active";
                    newState.scroll = true;
                    break;
                default:
                    newState.businessUp = "active";
            }
            state = newState;
        }
        else
        {
            newState.update = false;
            newState.scroll = false;
            state = newState;
        }

        return state;
    }

    changeProduct(product)
    {
        let newState = {"businessUp": "", "crowdfunding": "", "toolkit": "", "update": true};
        newState[product] = "active";
        Products.scroll(130);     
        this.setState(newState);
    }

    componentDidUpdate()
    {
        if (this.state.scroll === true)
        {
            Products.scroll(130);
        }
    }

    componentDidMount()
    {
        if (this.state.scroll === true)
        {
            Products.scroll(30);
        }
    }

    static scroll(headerOffset)
    {
        $(document.documentElement).animate({
            scrollTop: $(".product-option").offset().top-headerOffset
        }, 500);

        console.log('scrolled');
    }

    render()
    {
        return <Layout>
            <SEO 
                title={this.props.intl.formatMessage({id: "Products - Adventurees Alliance"})} 
                description={this.props.intl.formatMessage({id: "Discover Adventurees Saas platforms for crowdfunding, venture project creation or venture capital investment selection."})}
                location={this.props.location}
                image="/images/sharing/home-products.jpg"
            />
            
            <Banner 
                className="banner" 
                filename="products-banner.png" 
                title={this.props.intl.formatMessage({id: "Our technologies"})+", "+this.props.intl.formatMessage({id: "with your brand and image"})} 
                subtitle={this.props.intl.formatMessage({id: "Use our tools to manage your entrepreneurship and investment ecosystem"})}
            />
            { /* Page introduction */ }
            <Section>
                <div className="row align-items-center product-page page-introduction">
                    <div className="col-md-6 introduction-left products-left">
                    <p>{this.props.intl.formatMessage({id: "Fast, flexible and fully customizable"})}</p>
                    <h3>
                        {this.props.intl.formatMessage({id: "Connect with your network and finance your business"})}
                    </h3>
                    </div>
                    <div className="col-md-6 introduction-right products-right">
                        <p>{this.props.intl.formatMessage({id: "Our products have been built from our years of experience in the world of entrepreneurship and investment. Each of them can help you in the different value propositions you want to offer to your own clients, from business development to the financing of these, using different digital channels. Contract our products here or contact us to schedule a meeting"})}:</p>
                        <ul>
                            <li>{this.props.intl.formatMessage({id: "BusinessUp Platform"})}</li>
                            <li>{this.props.intl.formatMessage({id: "Crowdfunding Platform"})}</li>
                            <li>{this.props.intl.formatMessage({id: "Goldsmith® Toolkit"})}</li>
                        </ul>
                    </div>
                </div>
            </Section>

            { /* Product selector */ }
            <Section id="products">
                <div className="container">
                    <div className="row justify-content-center product-selector">
                        <div onClick={() => this.changeProduct("businessUp")} className={"col-md-3 product-option " + this.state.businessUp} role="tab" tabIndex={-1}>
                            <div className="selector-container">
                                <span>Business up</span>
                            </div>
                        </div>
                        <div onClick={() => this.changeProduct("crowdfunding")} className={"col-md-3 product-option " + this.state.crowdfunding} role="tab" tabIndex={-2}>
                            <div className="selector-container">
                                <span>Crowdfunding</span>
                            </div>
                        </div>
                        <div onClick={() => this.changeProduct("toolkit")} className={"col-md-3 product-option " + this.state.toolkit} role="tab" tabIndex={-3}>
                            <div className="selector-container">
                                <span>{this.props.intl.formatMessage({id: "Goldsmith® Toolkit"})}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>

            { /* BusinessUp */ }
            {this.state.businessUp && 
            <Section>
                <div id="product" className="container business-up product">
                    <div className="row">
                        <div className="product-title mobile">
                            <AdvTitle title="BusinessUp"/>
                        </div>
                        <div className="col-md-6 align-self-center">
                            <Image filename="products-business-up-laptop.png" />
                            <div className="free-trial-seal desktop">
                                <div className="free-trial-text">
                                    {this.props.intl.formatMessage({id: "Try it"})}
                                    <span>{this.props.intl.formatMessage({id: "Free"})}</span>
                                </div>
                                <div className="free-trial-period">
                                    <span>{this.props.intl.formatMessage({id: "ONLY PAY AT"})}</span>
                                    {this.props.intl.formatMessage({id: "SUBSCRIPTION ACTIVATION"})}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 product-features">
                            <div className="product-title desktop">
                                <AdvTitle title="BusinessUp"/>
                            </div>
                            <ul>                                
                                <li>{this.props.intl.formatMessage({id: "Own web"})}</li>
                                <li>{this.props.intl.formatMessage({id: "Usage of your own domain"})}</li>
                                <li>{this.props.intl.formatMessage({id: "Brand Look&Feel"})}</li>
                                <li>{this.props.intl.formatMessage({id: "Customer's data and contents"})}</li>
                                <li>{this.props.intl.formatMessage({id: "Editable pages"})}</li>
                                <li>{this.props.intl.formatMessage({id: "Reception and filtering projects"})}</li>
                                <li>{this.props.intl.formatMessage({id: "Goldsmith® model and analysis KPIs"})}</li>
                                <li>{this.props.intl.formatMessage({id: "Business document management"})}</li>
                                <li>{this.props.intl.formatMessage({id: "Online mentoring space"})}</li>
                                <li>{this.props.intl.formatMessage({id: "Business showcase"})}</li>
                                <li>{this.props.intl.formatMessage({id: "Events and community"})}</li>
                                <li>{this.props.intl.formatMessage({id: "100% autonomous administration"})}</li>
                                <li>{this.props.intl.formatMessage({id: "Available in Spanish, English and Portuguese"})}</li>
                            </ul>
                            <div className="form-trigger">
                                <p className="free-trial-trigger-text">{this.props.intl.formatMessage({id: "For 149€ per month"})}</p>
                                <div className="free-trial-seal mobile">
                                    <div className="free-trial-text">
                                        {this.props.intl.formatMessage({id: "Try it"})}
                                        <span>{this.props.intl.formatMessage({id: "Free"})}</span>
                                    </div>
                                    <div className="free-trial-period">
                                        <span>{this.props.intl.formatMessage({id: "ONLY PAY AT"})}</span>
                                        {this.props.intl.formatMessage({id: "SUBSCRIPTION ACTIVATION"})}
                                    </div>
                                </div>
                                <Link to="/businessup">{this.props.intl.formatMessage({id: "Know more"})}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>}

            { /* Crowdfunding */ }
            {this.state.crowdfunding &&
            <Section>
                <div id="crowdfunding" className="container crowdfunding product">
                    <div className="row">
                        <div className="product-title mobile">
                            <AdvTitle title="Crowdfunding"/>
                        </div>
                        <div className="col-md-6 align-self-center">
                            <Image filename="products-crowdfunding-laptop.png" />
                        </div>
                        <div className="col-md-6 product-features">
                            <div className="product-title desktop">
                                <AdvTitle title="Crowdfunding"/>
                            </div>
                            <ul>
                                <li>{this.props.intl.formatMessage({id: "Includes all functionalities from BusinessUp"})}</li>
                                <li>{this.props.intl.formatMessage({id: "Crowdfunding of donations and rewards"})}</li>
                                <li>Equity crowdfunding</li>
                                <li>Crowdlending</li>
                                <li>{this.props.intl.formatMessage({id: "Payment gateways and wallets"})}</li>
                                <li>{this.props.intl.formatMessage({id: "Custom designs and developments"})}</li>
                                <li>{this.props.intl.formatMessage({id: "100% autonomous administration"})}</li>
                                <li>{this.props.intl.formatMessage({id: "Administrator training and support"})}</li>
                                <li>{this.props.intl.formatMessage({id: "Available in Spanish, English and Portuguese"})}</li>
                            </ul>
                            <div className="form-trigger">
                                <p className="free-trial-trigger-text">{this.props.intl.formatMessage({id: "Tailor-made budget"})}</p>
                                <Link to="/contact">{this.props.intl.formatMessage({id: "Contact"})}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>}

            { /* Toolkit Goldsmith */ }
            {this.state.toolkit &&
            <Section>
                <div id="toolkit" className="container toolkit product">
                    <div className="row">
                        <div className="product-title mobile">
                            <AdvTitle title={this.props.intl.formatMessage({id: "Goldsmith® Toolkit"})} />
                        </div>
                        <div className="col-md-6">
                            <Image filename="products-toolkit.png" />
                        </div>
                        <div className="col-md-6 product-features">
                            <div className="product-title desktop">
                                <AdvTitle title={this.props.intl.formatMessage({id: "Goldsmith® Toolkit"})} />
                            </div>
                            <ul>
                                <li>{this.props.intl.formatMessage({id: "Evaluate the state of business periodically"})}</li>
                                <li>{this.props.intl.formatMessage({id: "Measure the strength of the business, the risks and its attractiveness to investors"})}</li>
                                <li>{this.props.intl.formatMessage({id: "Use our simulator for quick evaluations of your business"})}</li>
                                <li>{this.props.intl.formatMessage({id: "Calculate the value of companies by different methods (Classic, Discounted Cash Flow, Profit Multiple, VC Method, Goldsmith Method)"})}</li>
                            </ul>
                            <div className="form-trigger">
                                <p className="free-trial-trigger-text">{this.props.intl.formatMessage({id: "For 49€ per year"})}</p>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.adventurees.com/static/goldsmith_model.html">{this.props.intl.formatMessage({id: "Know more"})}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>}
        </Layout>;
    }
}

export default injectIntl(Products);
